<template>
  <div class="total">
    <div class="configure">
      <div class="work-title bg-white flex justify-content-center">
        <el-button
          round
          :class="{ 'color-box': sceneSwitching }"
          @click="sceneSwitching_(true)"
          >家校模板</el-button
        >
        <el-button
          :class="{ 'color-box': !sceneSwitching }"
          round
            disabled
          >招生宝场景</el-button
        >
      </div>
      <div
        v-if="sceneSwitching"
        class="work-title bg-white flex flex-wrap-wrap justify-content-center"
      >
        <div
          style="padding: 10px"
          class="flex flex-wrap-wrap template-item"
          v-for="(item, index) in 20"
          :key="index"
        >
          <img
            src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"
            alt=""
          />
          <div class="template-item-cover">
            <el-button @click="JumpUrl('/parentCenter/newNotification',index)" type="primary">点我使用</el-button>
            <el-button>预览模板</el-button>
          </div>
          <div class="temlate-desc">
            <div class="demonstration text-lg  ">
              <span>放假通知</span>
            </div>
            <div class="demonstration text-sm margin-top-sm">
              <span>返校复学，家长可以从以下方面帮助孩子提前做好复课准备</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!sceneSwitching" class="work-title bg-white"></div>
    </div>
  </div>
</template>
<script>
import AsList from "@/components/as-list";
export default {
  components: {
    AsList,
  },
  data() {
    return {
      sceneSwitching: true, //场景切换
      
    };
  },
  methods: {
   
    sceneSwitching_(t) {
      if (t) {
        this.sceneSwitching = true;
      } else {
        this.sceneSwitching = false;
      }
    },
    JumpUrl(url,id){
      this.$router.push(url+"?id="+id);
    },
  },
};
</script>

<style lang="scss">
.template-item {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  .temlate-desc {
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: -100px;
    padding: 10px;
  }
  img {
    width: 100%;
    display: block;
  }
  &:hover {
    .template-item-cover {
      display: flex;
    }
  }
  .template-item-cover {
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    display: none;
  }
}
.color-box {
  color: #0081ff;
  border: 1px solid #8cc5ff;
  background-color: #e8f5ff;
}
.total {
  width: 100%;
}
.TopLabel {
  widows: 200px;
}
.TopLabel_1 {
  widows: 300px;
}
.configure {
  width: 100%;
  background-color: #fff;
  padding: 30px 10px 10px 10px;
}
</style>